import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
// import LandingPage from './pages/LandingPage/LandingPage';
import LoginPage from "./pages/AuthPage/LoginPage";
import RegisterPage from "./pages/AuthPage/RegisterPage";
import OtpVerificationPage from "./pages/AuthPage/OtpVerificationPage";
// import { ToastContainer } from 'react-toastify';
import PasswordResetPage from "./pages/AuthPage/PasswordResetPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import HomePage from "./pages/HomePage/HomePage";
import CreateProjectPage from "./pages/CreateProjectPage/CreateProjectPage";
import SearchProjectPage from "./pages/SearchProjectPage/SearchProjectPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
// import { useEffect } from 'react';
import TostMessageComponent from "./components/TostMessageComponent/TostMessageComponent";
import OngoingProjectsPage from "./pages/OngoingProjectsPage/OngoingProjectsPage";
import PastProjectsPage from "./pages/PastProjectsPage/PastProjectsPage";
import FinancePage from "./pages/FinancePage/FinancePage";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import { VerificationProvider } from "./context/VerificationContext";
import ViewOngoingProjectsPage from "./pages/OngoingProjectsPage/ViewOngoingProjectsPage";
import { ProjectsProvider } from "./context/ProjectsContext";
import ApplicationPage from "./pages/ApplicationPage/ApplicationPage";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import ChatPage from "./pages/ChatPage/ChatPage";
import CallPage, { VideoCallCardHover } from "./pages/CallPage/CallPage.jsx";
// import ProjectBidsPage from "./pages/ProjectBidsPage/ProjectBidsPage";
import FAQPage from "./pages/FAQPage/FAQPage";
import ActivityTracker from "./pages/ActivityTracker/ActivityTracker.jsx";
import {
  VideoCallContext,
  VideoCallProvider,
} from "./context/videoCallContext";
import { useContext, useEffect } from "react";
import { PushNotificationContext } from "./context/PushNotificationContext";
import ProjectsGallery from "./pages/Gallery/projects-gallery";
import EditProjectPage from "./pages/EditProject/EditProject.jsx";
import addNotification, { Notifications } from "react-push-notification";
import { useCookies } from "react-cookie";
import { onMessage } from "firebase/messaging";
import { generateToken, messaging } from "./notification/firebase.js";
import { AuthContext } from "./context/AuthContext.jsx";

function App() {
  const { callButtonClicked, incomingCall, setIncomingCall } =
    useContext(VideoCallContext);
  const [cookies] = useCookies();
  const { userProfile } = useContext(AuthContext);

  const {
    isPushNotificationSupported,
    sendNotification,
    initializePushNotifications,
    registerServiceWorker,
  } = useContext(PushNotificationContext);

  useEffect(() => {
    const pushNotificationSuported = isPushNotificationSupported();
    if (pushNotificationSuported) {
      registerServiceWorker();
      initializePushNotifications().then(function (consent) {
        if (consent === "granted") {
          // sendNotification();
        }
      });
    }
  }, []);

  useEffect(() => {
    generateToken(
      cookies?.urbexManagerUserToken,
      cookies?.userManagerAccountIds?.account_id
    );
    onMessage(messaging, (payload) => {
      addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        theme: "darkblue",
        native: true,
        icon: payload.notification.image,
      });
    });
  }, [userProfile]);

  return (
    <div>
      <Notifications />
      <TostMessageComponent />
      <Routes>
        {/* landing page route */}
        {/* <Route  path="/" element={<LandingPage />} /> */}
        {/* redirect to login page */}
        <Route exact path='/' element={<Navigate replace to='/login' />} />
        <Route exact path='/login' element={<LoginPage />} />
        <Route exact path='/register' element={<RegisterPage />} />
        <Route exact path='/verify/:email' element={<OtpVerificationPage />} />
        <Route exact path='/password-reset' element={<PasswordResetPage />} />

        <Route
          exact
          path='/dashboard'
          element={<Navigate replace to='/dashboard/home' />}
        />
        <Route
          path='/dashboard'
          element={
            <VerificationProvider>
              <ProjectsProvider>
                <Dashboard />
              </ProjectsProvider>
            </VerificationProvider>
          }
        >
          <Route path='home' element={<HomePage />} />
          <Route
            path='search-project'
            element={
              <ProjectsProvider>
                <SearchProjectPage />
              </ProjectsProvider>
            }
          />
          <Route
            path='search-project/:id'
            element={
              <ProjectsProvider>
                <ProjectPage />
              </ProjectsProvider>
            }
          />
          <Route
            path='ongoing-projects'
            exact
            element={<OngoingProjectsPage />}
          />
          <Route path='projects-gallery' exact element={<ProjectsGallery />} />
          <Route path='activity-tracker' exact element={<ActivityTracker />} />
          <Route
            path='ongoing-projects/:id'
            element={<ViewOngoingProjectsPage />}
          />
          <Route path='subscriptions/:id' exact element={<Subscriptions />} />
          <Route path='past-projects' element={<PastProjectsPage />} />
          {/* <Route path="project-bids" element={<ProjectBidsPage />} /> */}
          <Route path='create-project' element={<CreateProjectPage />} />
          <Route path='applications' element={<ApplicationPage />} />
          <Route
            path='past-projects/:id'
            element={<ViewOngoingProjectsPage />}
          />
          <Route path='edit-project/:id' element={<EditProjectPage />} />
          <Route path='ongoing-projects/:id/chat' element={<ChatPage />} />
          <Route path='ongoing-projects/:id/call' element={<CallPage />} />
          <Route path='finance' element={<FinancePage />} />
          <Route path='profile' element={<ProfilePage />} />
          <Route path='faq' element={<FAQPage />} />
        </Route>

        {/* Routing fallback
        <Route path="*" element={<Navigate replace to="/404" />} />
           {/* Routing fallback * /}
        <Route path="/404" element={<Error404Page />} />
        */}
      </Routes>
    </div>
  );
}

export default App;

// function CompExample() {
//   const {
//     isOpen: isVisible,
//     onClose,
//     onOpen,
//   } = useDisclosure({ defaultIsOpen: false })
//   useEffect(() => {
//     onOpen()

//   }, [onOpen])
//   return isVisible ? (
//     <Alert status='success'>
//       <AlertIcon />
//       <Box>
//         <AlertTitle>Success!</AlertTitle>
//         <AlertDescription>
//           Your application has been received. We will review your application
//           and respond within the next 48 hours.
//         </AlertDescription>
//       </Box>
//       <CloseButton
//         alignSelf='flex-start'
//         position='relative'
//         right={-1}
//         top={-1}
//         onClick={onClose}
//       />
//     </Alert>
//   ) : (
//     <></>
//   )
// }
