import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import {
  fetch_my_projects_end_point,
  fetch_user_profile_end_point,
  search_projects_end_point,
  select_project_by_id_end_point,
} from "../config/variables";
import { AuthContext } from "./AuthContext";
import { client_profile_end_point } from "./../config/variables/index";
import { TostMessageContext } from "./TostMessage";
import { useNavigate, useParams } from "react-router-dom";

export const ProjectsContext = createContext();

export function ProjectsProvider({ children }) {
  const [filterOpen, setFilterOpen] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const [cookies] = useCookies();

  const [searchData, setSearchData] = useState({
    searching: false,
    budget: 0,
    category: "",
    location: "",
    offset: 0,
    records: 30,
  });
  const [listProjects, setListProjects] = useState({
    loading: true,
    data: [],
  });
  const [userProjects, setUserProjects] = useState({
    loading: true,
    data: {},
  });

  const [projectDetailes, setProjectDetailes] = React.useState({
    loading: true,
    data: null,
  });

  const { setTostMessage } = React.useContext(TostMessageContext);
  const navigate = useNavigate();

  async function getProjects(sData) {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: sData,
    };
    try {
      const response = await axios.post(search_projects_end_point, data, {
        headers: {
          access: cookies.urbexManagerUserToken,
        },
      });
      if (!response.data.status && response.data.statusCode === 401) {
        // navigate("/login");
        return setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
      setListProjects({
        loading: false,
        data: response.data.projects,
      });
      // console.log("Projects", response.data);
    } catch (error) {
      // console.log(error);
    }
  }
  // async function getProject(project_id) {
  //   if (listProjects?.data?.length === 0) {
  //     getProjects();
  //   }
  //   listProjects?.data?.map((project) => {
  //     if (project?.project_id === project_id) {
  //       // console.log("get project", project);
  //       return project;
  //     } else {
  //       return null;
  //     }
  //   });
  // }
  const { id } = useParams();

  const getProjectDetailes = useCallback(async () => {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
      },
    };
    try {
      const response = await axios.post(select_project_by_id_end_point, data, {
        headers: {
          access: cookies.urbexManagerUserToken,
        },
      });
      // console.log("Project", id, response.data.projects);
      // return response.data;
      setProjectDetailes({
        loading: false,
        data: response.data.projects,
      });
    } catch (error) {
      console.log(error);
    }
  }, [id, userProfile, cookies.urbexManagerUserToken, setProjectDetailes]);

  async function getUserProjects() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        offset: 0,
        records: 30,
      },
    };
    try {
      const response = await axios.post(fetch_my_projects_end_point, data, {
        headers: {
          access: cookies.urbexManagerUserToken,
        },
      });
      if (!response.data.status && response.data.statusCode === 401) {
        // navigate("/login");
        return setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
      setUserProjects({
        loading: false,
        data: response.data,
      });
      // // console.log("Projects", userProfile.account_id, response.data);
      // return response.data.projects;
    } catch (error) {
      // console.log(error);
    }
  }

  async function getClentProfile(profile_id) {
    const data = {
      user: {
        account_id: userProfile?.account_id,
      },
      data: {
        profile_id: profile_id,
      },
    };
    try {
      const response = await axios.post(fetch_user_profile_end_point, data, {
        headers: {
          access: cookies.urbexManagerUserToken,
        },
      });
      // console.log("profile response", response);
      return response.data;
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    // getProjects(searchData);
    // getUserProjects();
  }, []);

  const handleResetSearchProject = () => {
    let data = {
      searching: false,
      budget: 0,
      category: "",
      location: "",
      offset: 0,
      records: 30,
    };
    getProjects(data);
    // setFilteredProjects(listProjects); // Reset filtered Projects  back to the original list
  };

  return (
    <ProjectsContext.Provider
      value={{
        setSearchData,
        listProjects,
        searchData,
        getProjects,
        // getProject,
        filterOpen,
        setFilterOpen,
        getClentProfile,
        userProjects,
        getUserProjects,
        handleResetSearchProject,
        projectDetailes,
        setProjectDetailes,
        getProjectDetailes,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
}
