import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import "./Modal.scss";
// import imageOne from "../../assets/images/owner.png";
// import imageTwo from "../../assets/images/contractor.jpg";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { TostMessageContext } from "../../context/TostMessage";
import { IoMdArrowRoundBack } from "react-icons/io";
import {survey_endpoint} from '../../config/variables/index'
import calendar from '../../assets/images/calendar.svg';

const Modal = ({ isOpen, onClose }) => {
    // Always call useState at the top level
    const [category, setCategory] = useState('');
    const [showModal, setShowModal] = useState(true);
    const [showOwner, setShowOwner] = useState(false);
    const [showContractor, setShowContractor] = useState(false);
    const [cookies] = useCookies();
    const { setTostMessage } = React.useContext(TostMessageContext);
    const { isLoggedIn, isLoading} = useContext(AuthContext);
    const { userProfile, forceLogOutFunction, getUserProfileFunction } = useContext(AuthContext);
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([]);

    // State to store form data
    const [formData, setFormData] = useState({
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.account_id,
        lga: '',
        state: '',
        country: '',
    });
    const [formData2, setFormData2] = useState({
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.account_id,
        account_type: 'contractor',
        contractorType: '',
        teamSize: '',
        receiveOpportunities: ''
      });
    // State to manage loading state
    const [loading, setLoading] = useState(false);

    const handleOptionClick = (cat) => {
        setCategory(cat);
    };

    const toggleNextModal = () => {
        setShowOwner(true);
        setShowModal(false);
    };
    

    // Function to handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name == "state") {
            getLgas(value);
        }

        setFormData({
        ...formData,
        [name]: value
        });
    };

    const handleInputChange2 = (event) => {
        const { name, value } = event.target;
        setFormData2({
        ...formData2,
        [name]: value
        });
    };

    const handleBack = () => {
        setShowOwner(false);
        setShowModal(true);
        setShowContractor(false);
    }

    const getStates = async () => {
        const formData = new FormData();
        formData.append('type', 'STATES');
        // formData.append('state', 'STATES') 

        const response = await axios.post('https://api.urbex.africa/project_manager_backend/components/fetch_states_lgas.php', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        setStates(response.data.response)
    }

    useEffect(() => {
        getStates();
    }, [])

    const getLgas = async (state) => {
        const formData = new FormData();
        formData.append('type', 'LGAS');
        formData.append('state', state) 

        const response = await axios.post('https://api.urbex.africa/project_manager_backend/components/fetch_states_lgas.php', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        setLgas(response.data.response)
    }


    if (!isOpen) return null;

    // Function to handle form submission
    const handleSubmit = async () => {
        setLoading(true); // Start loading
          try {
            const response = await axios.post(survey_endpoint, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "access": cookies.urbexManagerUserToken, // Ensure the header name and value are correctly set
                },
                
            });

            setLoading(false);
            if (response.data.statusCode === 401) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                forceLogOutFunction();
                return;
              }
              if (!response.data.status) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                return;
            }
            setTostMessage({
                messageType: "success",
                message: response.data.response,
            });
            setShowContractor(true);
            setShowOwner(false);
          } catch (error) {
            setLoading(false);
            return;
          }
    };

    const scheduleInterview = async () => {
        window.open('https://calendar.app.google/xkxYjvZ8JZhdkuCAA', '_blank')
        getUserProfileFunction();
    }
    
    const handleSubmit2 = async () => {
        setLoading(true); // Start loading
          try {
            const response = await axios.post('https://api.urbex.africa/backend/profile/set_contractor_survey.php', formData2, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "access": cookies.urbexEnterpriseUserToken, // Ensure the header name and value are correctly set
                },
                
            });
            setLoading(false);
            if (response.data.statusCode === 401) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                forceLogOutFunction();
                return;
              }
              if (!response.data.status) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                return;
            }
            setTostMessage({
                messageType: "success",
                message: response.data.response,
            });
            getUserProfileFunction();
          } catch (error) {
            setLoading(false);
            return;
          }
    };

    return (
        <div className="backdrop">
            {showModal && (
                <div className="modal_container justify-between">
                    <div className="text-left mb-0">
                        <h3 className="mb-0 text-[25px] font-bold">Hello {userProfile?.name},</h3>
                        <h3 className="mb-0 text-[17px] font-semibold">Great to have you join the program!</h3>
                    </div>
                    <div className="w-[100%] gap-y-2 flex flex-column mx-auto my-3 items-center">
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[20px]">1</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Set up your profile</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">2</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Receive multiple projects invites around you</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">3</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Supervise up to 8 projects simultaneously</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">4</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Manage all aspects of your projects using this App</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">5</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Voila! Its that easy</a>
                        </div>
                    </div>
                    <button onClick={toggleNextModal} className="continue bg-native-blue w-4/5 mx-auto !rounded-full" type="button">
                        Set Up Profile
                    </button>
                    
                </div>
            )}

            {showOwner && (
                <div className="modal_container">
                    <div className="text-left mb-3">
                        <button
                            className={`bg-transparent w-auto mb-3 !rounded-full border-3 border-black p-1`}
                            onClick={handleBack}
                            type="button"
                        >
                            <IoMdArrowRoundBack size={20} />
                        </button>
                        <h3 className="mb-0 text-[20px] font-semibold">Help us maximise your opportunities</h3>
                        <h3 className="mb-0 text-[17px]">Where do you reside?</h3>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <div className="flex flex-col items-start">
                            <label htmlFor="team_size">Select Country</label>
                            <select
                            name="country"
                            id="country"
                            className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                            onChange={(e) => handleInputChange(e)}
                            >
                            <option value="">Select Country</option>
                            <option value="Nigeria">Nigeria</option>
                            </select>
                        </div>
                        <div className="flex flex-col items-start">
                            <label htmlFor="team_size">Select State</label>
                            <select
                            name="state"
                            id="state"
                            className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                            onChange={(e) => handleInputChange(e)}
                            >
                            <option value="">Select a state</option>
                            {states?.map((item, index) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                            </select>
                        </div>
                        <div className="flex flex-col items-start">
                            <label htmlFor="discoveryMethod">Select LGA</label>
                            <select
                                name="lga"
                                id="lga"
                                className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                                onChange={(e) => handleInputChange(e)}
                            >
                            <option value="">Select an LGA</option>
                            {lgas?.map((item, index) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                            </select>
                        </div>
                        <div className="flex justify-content-between">
                            {(formData.lga == '' || formData.state == '' || formData.country == '' || formData.team_size == '') ? 
                            (<button className="continue w-50 mt-3 mx-auto !rounded-full disabled" type="button" role="disabled">
                                Next
                            </button>):
                            (<button
                                className={`continue w-50 mx-auto mt-3 bg-[#000aff] !rounded-full ${loading ? 'loading' : ''}`}
                                onClick={handleSubmit}
                                type="button"
                                disabled={loading}
                            >
                                {loading ? 'Please Wait...' : 'Next'}
                            </button>) }
                            
                        </div>
                        
                        </div>
                </div>
            )}

        {showContractor && (
            <div className="modal_container">
            <div className="text-left mb-3">
                {/* <button
                    className={`bg-transparent w-auto mb-3 !rounded-full border-3 border-black p-1`}
                    onClick={handleBack}
                    type="button"
                >
                    <IoMdArrowRoundBack size={20} />
                </button> */}
                <h3 className="mb-0 text-[25px] font-bold">You're all set {userProfile?.name}</h3>
                <h3 className="mb-0 text-[17px] font-semibold">Schedule a date for your rating interview</h3>
            </div>
            <div className="flex flex-col gap-y-2">
                <img src={calendar} className="object-cover mx-auto h-50 w-50" />
                <div className="flex justify-content-between">
                    <button
                        className={`continue bg-native-blue w-50 mx-auto mt-3 !rounded-full`}
                        onClick={scheduleInterview}
                        type="button"
                    >
                        Schedule Interview
                    </button>
                </div>
            </div>
            </div>
        )}
        </div>
    );
};

export default Modal;
