import { BsCashCoin } from 'react-icons/bs';
import { ImHome, ImCalendar } from 'react-icons/im';
import { MdOutlinePendingActions, MdOutlineImage } from 'react-icons/md';
import {
  RxDashboard,
  RxAvatar,
  RxMagnifyingGlass,
  RxBarChart,
  RxHelpCircle,
} from 'react-icons/rx';
import { TbHelp, TbPlus } from 'react-icons/tb';
import { v4 as uuidv4 } from 'uuid';

export const DashboardSideNavLinks = [
  {
    id: uuidv4(),
    icon: <RxDashboard />,
    name: 'Home',
    path: '/dashboard/home',
    accountType: 'both',
  },
  {
    id: uuidv4(),
    icon: <RxMagnifyingGlass />,
    name: 'Find Projects',
    path: '/dashboard/search-project',
    accountType: 'contractor',
  },
  {
    id: uuidv4(),
    icon: <TbPlus />,
    name: 'Create Project',
    path: '/dashboard/create-project',
    accountType: 'client',
  },
  // {
  //   id: uuidv4(),
  //   icon: <MdOutlinePendingActions />,
  //   name: "Ongoing Project",
  //   path: "/dashboard/ongoing-projects",
  //   accountType: "contractor",
  // },
  {
    id: uuidv4(),
    icon: <MdOutlinePendingActions />,
    name: 'My Projects',
    path: '/dashboard/ongoing-projects',
    accountType: 'both',
  },
  // {
  //  id: uuidv4(),
  //  icon: <MdOutlineImage />,
  //  name: 'Media Library',
  //  path: '/dashboard/projects-gallery',
  //  accountType: 'both',
  // },
  // {
  //   id: uuidv4(),
  //   icon: <ImCalendar />,
  //   name: "Project History",
  //   path: "/dashboard/past-projects",
  //   accountType: "both",
  // },
  {
    id: uuidv4(),
    icon: <ImCalendar />,
    name: 'Applications',
    path: '/dashboard/applications',
    accountType: 'contractor',
  },
  // {
  //   id: uuidv4(),
  //   icon: <ImCalendar />,
  //   name: "Project Bids",
  //   path: "/dashboard/project-bids",
  //   accountType: "client",
  // },
  {
    id: uuidv4(),
    icon: <BsCashCoin />,
    name: 'Earnings',
    path: '/dashboard/finance',
    accountType: 'both',
  },
  {
    id: uuidv4(),
    icon: <RxAvatar />,
    name: 'Profile',
    path: '/dashboard/profile',
    accountType: 'both',
  },
  {
    id: uuidv4(),
    icon: <TbHelp />,
    name: 'Help',
    path: '/dashboard/faq',
    accountType: 'both',
  },
];
