// VerificationContext
import axios from 'axios';
import React from 'react';
import IntroductionStep from '../components/AccountSetUpCard/IntroductionStep';
import Step1FormComponent from '../components/AccountSetUpCard/Step1FormComponent';
import Step2FormComponent from '../components/AccountSetUpCard/Step2FormComponent';
import SuccessStep from '../components/AccountSetUpCard/SuccessStep';
import {
  verify_contractor_information_end_point,
  verify_personal_information_end_point,
} from '../config/variables';
import { AuthContext } from './AuthContext';
import { TostMessageContext } from './TostMessage';

export const VerificationContext = React.createContext();

export function VerificationProvider({ children }) {
  const { userProfile, getUserProfileFunction } = React.useContext(AuthContext);
  const { setTostMessage } = React.useContext(TostMessageContext);
  const [isContractor, setIsContractor] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [verifyCorporateFormData, setVerifyCorporateFormData] = React.useState({
    account_id: '',
    corporate_id_number: '',
    corporate_id_file: null,
    display_picture: null,
    country: '',
    state: '',
  });

  const [verifyUserFormData, setVerifyUserFormData] = React.useState({
    phone: '',
    country: '',
    state: '',
    address: '',
    utility_bill: null,
    referrer: '',
    id_number: '',
    id_file: null,
    company_logo: null
  });

  const [stepsContents, setStepsContents] = React.useState([
    {
      headers: {
        title: 'Start Verification',
        done: true,
      },
      title: 'Profile verification',
      descriptions:
        'We need to verify your identity and this requires that you upload some documents for review.',
      content: <IntroductionStep handelNext={handelNext} />,
    },
    {
      headers: {
        title: 'Personal Info.',
        done: userProfile?.docs_personal_info,
      },
      title: 'Personal Information',
      descriptions: 'Enter some information about your self to help us get to know you.',
      content: <Step1FormComponent handelNext={handelNext} loading={loading} />,
    },
    {
      headers: {
        title: 'KYC in Review',
        done: false,
      },
      title: 'KYC in Review',
      descriptions:
        'Please do hold on while we review your information. This could take up to 3 hours.',
      content: <SuccessStep />,
    },
  ]);

  function handelNext() {
    if (activeStep === stepsContents.length - 1) {
      return setActiveStep(0);
    }
    setActiveStep((prev) => prev + 1);
  }

  React.useEffect(() => {
    if (
      userProfile?.account_type === 'contractor' ||
      userProfile?.account_type === 'Contractor'
    ) {
      setIsContractor(true);
      setStepsContents([
        {
          headers: {
            title: 'Business Info.',
            done: userProfile?.docs_personal_info,
          },
          title: 'Business Information',
          descriptions:
            'Enter your company information to complete this section',
          content: (
            <Step1FormComponent
              loading={loading}
              formData={verifyUserFormData}
              setFormData={setVerifyUserFormData}
              // verifyUser={handelPersonalInformationVerification}
            />
          ),
        },
        {
          headers: {
            title: 'Corporate Info.',
            done: userProfile?.docs_contractor_info,
          },
          title: 'Corporate Information',
          descriptions:
            'Enter professional details about yourself and corporate entity.',
          content: (
            <Step2FormComponent
              formData={verifyUserFormData}
              setFormData={setVerifyUserFormData}
            />
          ),
        },
        {
          headers: {
            title: 'KYC in Review',
            done: false,
          },
          title: 'KYC in Review',
          descriptions:
            'Please do hold on while your documents are been reviewed for approval',
          content: <SuccessStep />,
        },
      ]);
    }

    // // console.log("stepsContents", stepsContents);
  }, [userProfile]);

  React.useEffect(() => {
    // console.log("loading change", loading);
  }, [loading]);
  React.useEffect(() => {
    let done = 0;

    stepsContents.forEach((step) => {
      if (step.headers.done) {
        done++;
      }
    });
    setActiveStep(done);
  }, [stepsContents]);

  async function handelPersonalInformationVerification() {
    console.log({ verifyUserFormData });
    const {
      gender,
      phone,
      country,
      state,
      address,
      utility_bill,
      referrer,
      id_number,
      id_file,
      display_picture
    } = verifyUserFormData;

    console.log({
      verifyUserFormData,
    });

    if (
      !phone ||
      phone === '' ||
      !country ||
      country === '' ||
      !state ||
      state === '' ||
      !address ||
      address === '' ||
      !utility_bill ||
      utility_bill === '' ||
      !id_number ||
      id_number === '' ||
      !id_file ||
      id_file === ''
    ) {
      setTostMessage({
        messageType: 'error',
        message: 'Please complete the form before submitting ',
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    // // console.log("userInfo", userInfo);
    // // console.log("before", formData);
    formData.append('account_id', userProfile?.account_id);
    // formData.append("occupation", occupation);
    formData.append('phone', phone);
    formData.append("country", country);
    formData.append('state', state);
    formData.append('address', address);
    formData.append('utility_bill', utility_bill);
    formData.append('referrer', referrer);
    formData.append('id_number', id_number);
    formData.append('id_file', id_file);
    formData.append('company_logo', display_picture);
    
    // // console.log("after", formData);
    // // console.log("userInfo", userInfo);
    // return // console.log({ verifyUserFormData });
    try {
      const response = await axios.post(
        verify_personal_information_end_point,
        {
          account_id: userProfile?.account_id,
          phone: phone,
          state: state,
          address: address,
          utility_bill,
          utility_bill,
          referrer: referrer,
          id_number,
          id_number,
          id_file,
          id_file,
          display_picture
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = await response.data;
      console.log(data)
      if (response.data.status) {
        getUserProfileFunction();
        setLoading(false);
        handelNext();
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handelCorporateInformationVerification() {
    setLoading(true);
    const {
      corporate_id_number,
      corporate_id_file,
      display_picture,
      country,
      state,
    } = verifyCorporateFormData;
    if (
      !corporate_id_number ||
      corporate_id_number === '' ||
      !corporate_id_file ||
      corporate_id_file === '' ||
      !display_picture ||
      display_picture === '' ||
      !country ||
      country === '' ||
      !state ||
      state === ''
    ) {
      setTostMessage({
        messageType: 'error',
        message: 'Please Fill Form Correctly ',
      });
      return;
    }

    const formData = new FormData();
    formData.append('account_id', userProfile?.account_id);
    formData.append('corporate_id_number', corporate_id_number);
    formData.append('corporate_id_file', corporate_id_file);
    formData.append('display_picture', display_picture);
    formData.append('country', country);
    formData.append('state', state);
    // // console.log(userProfile?.account_id);
    // return // console.log({ verifyCorporateFormData });
    try {
      const response = await axios.post(
        verify_contractor_information_end_point,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data.status) {
        // // console.log(response.data);

        getUserProfileFunction();
        setLoading(false);

        handelNext();
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  }

  return (
    <VerificationContext.Provider
      value={{
        isContractor,
        activeStep,
        setActiveStep,
        stepsContents,
        setStepsContents,
        handelNext,
        loading,
        setLoading,
        handelPersonalInformationVerification,
        handelCorporateInformationVerification,
        verifyUserFormData,
        setVerifyUserFormData,
        verifyCorporateFormData,
        setVerifyCorporateFormData,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
}
