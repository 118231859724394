import React from "react";
import FileUpload from "./FileUpload/FileUpload";
import FileList from "./FileList/FileList";

function MultipleFileUploadComponent({ files, setFiles, supportedFiles, accept }) {
  const removeFile = (index) => {
    let arrayCopy = [...files];
    // console.log(arrayCopy);
    arrayCopy.splice(index, 1); //remove the element at index 2
    // console.log(arrayCopy);
    setFiles(arrayCopy);
    // console.log(files.splice(index, 1));
  };
  React.useEffect(() => {
    // console.log("uploaded File: " ,files);
  }, [files]);
  return (
    <div className="">
      <FileUpload files={files} setFiles={setFiles} supportedFiles={supportedFiles} removeFile={removeFile} accept={accept}/>
      <sup className="desc">
        {/* You may attach up to files under the size of 25 MB each. Include work
        samples or other documents for this project. Do not attach your resume */}
      </sup>
      <FileList className="px-0" files={files} removeFile={removeFile} />
    </div>
  );
}

export default MultipleFileUploadComponent;
