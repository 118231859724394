import { Icon, FormLabel, Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState, Fragment } from "react";
import ReactLoading from "react-loading";
import CountriesData from "./CountryData.json";
import "./CreateProjectPage.scss";
import { LuImagePlus } from "react-icons/lu";
import { AuthContext } from "../../context/AuthContext";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  add_project,
  assign_contractor_end_point,
} from "../../config/variables";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { TostMessageContext } from "../../context/TostMessage";
import { getFileExtension } from "../../lib";

function CreateProjectPage() {
  const [images, setImages] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [fileErrState, setFileErrState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contractorError, setContractorError] = useState(false);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [country, setCountry] = useState("Nigeria");
  const [countryState, setCountryState] = useState("");
  const [countryErr, setCountryErr] = useState(false);
  const [projectCategory, setProjectCategory] = useState("");
  const [projectCategoryErr, setProjectCategoryErr] = useState(false);
  const [countryStateErr, setCountryStateErr] = useState(false);
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [zipFiles, setZIPs] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      site_address: "",
      type: "",
      desc: "",
      title: "",
      state: "",
      country: "",
      duration: "",
      budget: "",
    },
  });

  // document?.map((item) => {
  //   // @ts-ignore
  //   return formData.append("project_files[]", {
  //     uri: item.uri,
  //     name: item.name,
  //     type: item.type,
  //     size: item.size,
  //   });
  // });

  const onFileChange = (event) => {
    setFileErrState(false);
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const allowedFormats = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/zip",
      ];
      if (allowedFormats.includes(selectedFile.type)) {
        const newFile = {
          uri: URL.createObjectURL(selectedFile),
          name: selectedFile.name,
          type: selectedFile.type,
          size: selectedFile.size,
        };
        if (selectedFile.type.startsWith("image/")) {
          setImages((prevImages) => [...prevImages, newFile]);
        } else if (
          selectedFile.type === "application/pdf" ||
          selectedFile.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setDocuments((prevDocuments) => [...prevDocuments, newFile]);
        } else if (selectedFile.type === "application/zip") {
          setZIPs((prevZIPs) => [...prevZIPs, newFile]);
        }
      } else {
        alert("Please select a valid file format (image, pdf, docx, or zip).");
      }
    }
  };

  const removeDocument = (index) => {
    setDocuments(images.filter((_, i) => i !== index));
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };
  const removeZIP = (index) => {
    setImages(zipFiles.filter((_, i) => i !== index));
  };

  const addProject = async (data) => {
    setLoading(true);
    if (!projectCategory) {
      setProjectCategoryErr(true);
      setLoading(false);
      return;
    }

    if (!country) {
      setCountryErr(true);
      setLoading(false);
      return;
    }
    if (!countryState) {
      setLoading(false);
      setCountryStateErr("true");
      return;
    }

    console.log({ country, countryState, projectCategory, selectedPerson });
    const formData = new FormData();
    formData.append("account_id", userProfile?.account_id);
    formData.append("contractor_id[]", selectedPerson);
    formData.append("project_type", projectCategory);
    formData.append("project_description", data.desc);
    formData.append("project_budget", data.budget);
    formData.append(
      "project_files[]",
      [...images, ...zipFiles, ...documents].map((file) => ({
        uri: file.uri,
        name: file.name,
        type: file.type,
        size: file.size,
      }))
    );

    formData.append("project_title", data.title);
    formData.append("project_state", countryState);
    formData.append("project_country", country);
    formData.append("project_proposed_duration", data.duration);
    formData.append("site_address", data.site_address);
    formData.append("withdrawal_setting", "AUTO");
    formData.append("sub_account_id", userProfile?.sub_account_id);

    try {
      const response = await axios.post(add_project, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          access: cookies.urbexManagerUserToken,
        },
      });
      const data = await response.data;
      if (data.status) {
        setTostMessage({
          messageType: "success",
          message: data.response,
        });
        setLoading(false);
        navigate("/dashboard/ongoing-projects");
        // closeTabFunction();
      } else {
        setTostMessage({
          messageType: "error",
          message: data.response,
        });
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleErr = (err) => {
    if (!images.length) {
      setFileErrState(true);
    }
    // if (!selectedPerson) {
    //   setContractorError(true);
    // }
    if (!country) {
      setCountryErr(true);
    }
    if (!countryState) {
      setCountryStateErr(true);
    }
    if (!projectCategory) {
      setProjectCategoryErr(true);
    }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setCountryErr(false);
  };
  const handleProjectCategoryChange = (event) => {
    setProjectCategory(event.target.value);
    setProjectCategoryErr(false);
  };
  const handleStateChange = (event) => {
    setCountryState(event.target.value);
    setCountryStateErr(false);
  };
  function getCountryStates() {
    const states = CountriesData.countriesData
      .filter((item) => item.label === country)
      .map((i) => i.states);
    return states[0];
  }

  return (
    <form
      onSubmit={handleSubmit(addProject, handleErr)}
      className='CreateProjectPage px-6 lg:px-20'
    >
      <div className='text-2xl mt-10 font-bold flex justify-between'>
        <div className='text-sm md:text-2xl'>
          Please endeavor to fill in the right details
        </div>
        <div className=''>
          {loading ? (
            <ReactLoading className='' type={"cylon"} color='#000aff' />
          ) : (
            <button
              type='submit'
              className='bg-[#000aff] py-2 px-4 text-white text-[16px] text-center rounded-[5px] mt-0 px-0.7rem'
            >
              Add Project
            </button>
          )}
        </div>
      </div>
      <div className='mt-7'>
        <div className='flex flex-col-reverse xl:grid xl:grid-cols-7 gap-4'>
          <div className='xl:col-span-4'>
            <div className='form-container bg-white px-4 rounded-xl py-5'>
              <div className='flex flex-col md:flex-row items-center gap-x-5 justify-between -mt-7'>
                <Box className='my-3 flex-1 w-full'>
                  <FormLabel
                    style={{ fontSize: 13 }}
                    htmlFor='corporate_id_number'
                  >
                    Project Title
                  </FormLabel>
                  <input
                    {...register("title", { required: true })}
                    placeholder='Enter project title'
                    className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                      errors?.title && "outline-red-300"
                    }`}
                  />
                </Box>
                <Box className='my-3 flex-1 w-full'>
                  <FormLabel
                    style={{ fontSize: 13 }}
                    htmlFor='corporate_id_number'
                  >
                    Project Type
                  </FormLabel>

                  <select
                    value={projectCategory}
                    onChange={handleProjectCategoryChange}
                    placeholder='Enter project type'
                    className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                      projectCategoryErr && "outline-red-300"
                    }`}
                  >
                    <option value=''>Select Project Type</option>
                    {userProfile?.misc.project_categories.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Box>
              </div>

              <div className='flex flex-col md:flex-row items-center gap-x-5 justify-between'>
                <Box className='w-full md:my-3 flex-1 mb-3 md:mb-0'>
                  <FormLabel
                    style={{ fontSize: 13 }}
                    htmlFor='corporate_id_number'
                  >
                    Project Budget
                  </FormLabel>
                  <input
                    {...register("budget", { required: true })}
                    type='number'
                    placeholder='Enter project budget'
                    className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                      errors?.budget && "outline-red-300"
                    }`}
                  />
                </Box>
                <Box className='w-full md:my-3 flex-1'>
                  <FormLabel
                    style={{ fontSize: 13 }}
                    htmlFor='corporate_id_number'
                  >
                    Project Proposed Duration
                  </FormLabel>
                  <input
                    {...register("duration", { required: true })}
                    placeholder='Enter project proposed duration'
                    className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                      errors?.duration && "outline-red-300"
                    }`}
                  />
                </Box>
              </div>

              <Box className='my-3'>
                <FormLabel style={{ fontSize: 13 }}>
                  Project Description
                </FormLabel>
                <input
                  {...register("desc", { required: true })}
                  placeholder='Enter project description'
                  className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                    errors?.desc && "outline-red-300"
                  }`}
                />
              </Box>

              <Box className='my-3'>
                <FormLabel
                  style={{ fontSize: 13 }}
                  htmlFor='corporate_id_number'
                >
                  Project Location
                </FormLabel>
                <input
                  {...register("site_address", { required: true })}
                  placeholder='Enter site address'
                  className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                    errors?.site_address && "outline-red-300"
                  }`}
                />
              </Box>

              <div className='flex flex-col md:flex-row items-center gap-x-5 justify-between'>
                <Box className='my-3 flex-1 w-full'>
                  <FormLabel
                    style={{ fontSize: 13 }}
                    htmlFor='corporate_id_number'
                  >
                    Country Located
                  </FormLabel>
                  <select
                    value={country}
                    onChange={handleCountryChange}
                    placeholder='Enter country located'
                    className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                      countryErr && "outline-red-300"
                    }`}
                  >
                    {CountriesData.countriesData.map((item, index) => (
                      <option value={item.labal} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </Box>
                <Box className='my-3 flex-1 w-full'>
                  <FormLabel
                    style={{ fontSize: 13 }}
                    htmlFor='corporate_id_number'
                  >
                    State Located
                  </FormLabel>
                  <select
                    value={countryState}
                    onChange={handleStateChange}
                    placeholder='Enter state located'
                    className={`bg-gray-100 w-full rounded-lg p-2 border-none focus:outline-none outline-none ${
                      countryStateErr && "outline-red-300"
                    }`}
                  >
                    {getCountryStates().map((item, index) => (
                      <option value={item.labal} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </Box>
              </div>
            </div>
            <b className='mt-4 d-block'>
              Add media or document to project files (
              <b style={{ color: "red" }}>Double tap to remove</b>)
            </b>
            <div className='gallery-container container p-0'>
              <div className='grid grid-cols-2 md:grid-cols-3 gap-y-16 lg:grid-cols-4 grid-flow-row gap-x-8 mt-4'>
                {images?.map((image, idx) => (
                  <div className='h-32 md:h-56 relative'>
                    <div key={idx} className='h-32 md:h-56 relative'>
                      <img
                        src={image}
                        alt=''
                        onDoubleClick={() => removeImage(idx)}
                        className='h-full w-full rounded-lg transition-all object-cover'
                      />
                    </div>
                    <button
                      className='text-black bg-white border px-3 mt-2 py-1 rounded'
                      onClick={() => removeImage(idx)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                {documents?.map((document, idx) => {
                  const type = getFileExtension(document?.name);
                  return (
                    <div>
                      <embed
                        src={document.url}
                        type='application/pdf'
                        className='h-full w-full rounded-lg transition-all object-cover'
                      />
                      <button
                        className='text-black bg-white border px-3 mt-2 py-1 rounded'
                        onClick={() => removeDocument(idx)}
                      >
                        Remove
                      </button>
                    </div>
                  );
                })}
                {zipFiles?.map((zip, idx) => (
                  <div className='h-32 md:h-56 relative' key={idx}>
                    <a href={zip.url} download>
                      <div className='h-full w-full bg-gray-200 rounded-lg transition-all object-cover'>
                        <span>{zip.name}</span>
                      </div>
                    </a>
                    <button
                      className='text-black bg-white border px-3 mt-2 py-1 rounded'
                      onClick={() => removeZIP(idx)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <div
                  className={`h-32 md:h-56 relative bg-gray-50 border rounded-xl hover:scale-95 transition-all flex items-center justify-center ${
                    fileErrState && "outline outline-red-300"
                  }`}
                >
                  <input
                    type='file'
                    onChange={onFileChange}
                    className={`h-full w-full absolute bg-transparent opacity-0`}
                    accept='*/*'
                  />
                  <LuImagePlus size={60} className='rounded-lg ' />
                </div>
              </div>
            </div>
          </div>
          <div className='xl:col-span-3'>
            <SearchSkillsComponent
              setSelectedPerson={setSelectedPerson}
              contractorError={contractorError}
              setContractorError={setContractorError}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default CreateProjectPage;

function SearchSkillsComponent({
  setSelectedPerson,
  setContractorError,
  contractorError,
}) {
  const [contractors, setContractors] = useState([]);
  const [contractorsList, setContractorsList] = useState([]);
  const [query, setQuery] = useState("");
  const { userProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [cookies] = useCookies();
  const [error, setError] = useState("");

  const searchContractor = async () => {
    setLoading(true);
    if (query === "") {
      setLoading(false);
      return;
    }
    try {
      const data = {
        user: {
          account_id: userProfile?.account_id,
        },
        data: {
          email: query,
        },
      };
      if (userProfile?.account_id) {
        const response = await axios.post(assign_contractor_end_point, data, {
          headers: {
            access: cookies.urbexManagerUserToken,
          },
        });

        const resData = await response.data;
        if (resData.status) {
          setContractors([resData.data]);
          setShow(true);
          setLoading(false);
        } else {
          setError(resData.response);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg-white p-4 rounded-xl'>
      <h4 className='mb-3 text-xl'>Add contractor (Optional)</h4>
      <div className='w-full h-auto p-3 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
        <a className='text-sm text-center'>
          You can add your personal contractor to this project.
        </a>
      </div>
      <div className='flex mt-4 items-center h-10 bg-gray-50'>
        <input
          type='text'
          className={`w-full border border-gray-50 h-full rounded-tl-lg rounded-bl-lg px-3 outline-none border-none w-fill ${
            contractorError && "bg-red-200"
          }`}
          placeholder='Search email here...'
          onChange={(e) => {
            setQuery(e.target.value);
            setError("");
            setContractorError(false);
          }}
          value={query}
        />
        <div className='bg-[#000aff] cursor-pointer text-white h-full p-[10px] rounded-tr-lg rounded-br-lg'>
          {loading ? (
            <ReactLoading
              type={"cylon"}
              color='#ffffff'
              width={30}
              className='-mt-1'
            />
          ) : (
            <BsSearch size={20} onClick={searchContractor} />
          )}
        </div>
      </div>

      {contractors?.length !== 0 && show && (
        <div className='px-2 bg-white border max-w-xs transition-all rounded-lg mt-1'>
          {contractors.map((item, idx) => (
            <div
              key={idx}
              onClick={() => {
                setShow(false);
                setSelectedPerson((prevSelectedPerson) => [
                  ...prevSelectedPerson,
                  item.contractor_id,
                ]);
                setContractorsList((prevState) => [...prevState, item]);
                setQuery("");
                setContractorError(false);
              }}
              className='my-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3'
            >
              <img
                src={item.contractor_image}
                height={30}
                width={30}
                className='rounded-full'
                alt=''
              />
              <div>{item.contractor_name}</div>
            </div>
          ))}
        </div>
      )}
      {error && <div className='text-red-500 mt-3'>{error}</div>}

      <div className='px-2 bg-white mt-4 flex gap-x-3 flex-wrap transition-all rounded-lg'>
        {contractorsList?.map((item, idx) => (
          <div
            key={idx}
            onClick={() => {}}
            className='mt-2 bg-gray-50 border rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3'
          >
            <div>{item.contractor_name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
