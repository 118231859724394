import React from "react";
import { FilterScrollComponent } from "../OngoingProjectsPage/OngoingProjectsPage";
import { Icon, filter } from "@chakra-ui/react";
import "./ApplicationPage.scss";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useCookies } from "react-cookie";
import { project_applications_end_point } from "../../config/variables";
import { BsThreeDots } from "react-icons/bs";

function ApplicationPage() {
  const [applications, setApplications] = React.useState({
    loading: true,
    data: [],
  });
  const [applicationsFilter, setApplicationsFilter] = React.useState({
    filter: false,
    data: null,
  });
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  async function getApplications() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
      },
      data: {
        records: 10,
        offset: 0,
      },
    };
    try {
      const response = await axios.post(project_applications_end_point, data, {
        headers: {
          access: cookies.urbexManagerUserToken,
        },
      });
      // return response.data;
      setApplications({
        loading: false,
        data: response.data.bids,
      });
    } catch (error) {
      // console.log(error);
    }
  }
  React.useEffect(() => {
    getApplications();
  }, []);

  function filterApplications(i) {
    // console.log("filterApplications", i);
    if (i === 0) {
      return setApplicationsFilter({
        filter: false,
        data: null,
      });
    }
    let filterArray = [];
    if (i === 1) {
      // console.log(applications);
      applications.data.map((application) => {
        if (application.status === "Pending") {
          filterArray.push(application);
        }
      });
    }
    if (i === 2) {
      // console.log(applications);
      applications.data.map((application) => {
        if (application.status === "Accepted") {
          filterArray.push(application);
        }
      });
    }
    if (i === 3) {
      // console.log(applications);
      applications.data.map((application) => {
        if (application.status === "Rejected") {
          filterArray.push(application);
        }
      });
    }
    setApplicationsFilter({
      filter: true,
      data: filterArray,
    });
    // console.log("applicationsFilter", applicationsFilter);
  }

  return (
    <div className='ApplicationPage'>
      <FilterScrollComponent
        filtersArray={["All", "Pending", "Interview", "Rejected"]}
        handleFilerFunction={(i) => filterApplications(i)}
      />

      {applications.loading ? (
        <>Loading...</>
      ) : (
        <>
          {applicationsFilter.filter === false ? (
            <>
              {applications.data?.map((bid, i) => {
                return <ApplicationCardComponent bid={bid} key={i} />;
              })}
            </>
          ) : null}
          {applicationsFilter.filter === true ? (
            <>
              {applicationsFilter.data === null ||
              applicationsFilter.data.length <= 0 ? (
                <>No data available for filter</>
              ) : (
                <>
                  {applicationsFilter.data?.map((bid, i) => {
                    return <ApplicationCardComponent bid={bid} key={i} />;
                  })}
                </>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
}

export default ApplicationPage;

function ApplicationCardComponent({ bid }) {
  return (
    <div className='ApplicationCardComponent'>
      <div>
        <h1>{bid?.project_title}</h1>
        <p>{bid?.project_budget}</p>
      </div>
      <div className='badge-container'>
        <div className={`badge ${bid?.status}`}>
          {bid?.status === "Accepted" ? "Scheduled for interview" : bid?.status}
        </div>
      </div>
      <div className='icon-container'>
        {bid?.status === "Accepted" ? <Icon as={BsThreeDots} /> : null}
      </div>
    </div>
  );
}
