import React, { useEffect, useState } from "react";
import galleryImage from "../../assets/images/profile-card-cover-image.jpg";
import { AiOutlineClose } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { base_url } from "../../config/variables";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";

export default function ProjectsGallery() {
  const { userProfile } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [galleryItems, setGalleryItems] = useState(null);
  const [cookies] = useCookies();
  const getProjectGallery = async () => {
    try {
      const response = await axios.post(
        `${base_url}/projects/media_library.php`,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexManagerUserToken,
          },
        }
      );
      const data = await response.data;
      console.log(data);
      if (data.status) {
        setGalleryItems(data.response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProjectGallery();
  }, []);
  // const jsonData = [
  //   {
  //     title: 'Category A',
  //     items: [
  //       {
  //         image: galleryImage,
  //         title: 'image title A',
  //       },
  //       { image: galleryImage, title: 'image title B' },
  //       { image: galleryImage, title: 'image title C' },
  //     ],
  //   },
  //   {
  //     title: 'Category B',
  //     items: [
  //       { image: galleryImage, title: 'image title A' },
  //       { image: galleryImage, title: 'image title B' },
  //       { image: galleryImage, title: 'image title C' },
  //     ],
  //   },
  //   {
  //     title: 'Category C',
  //     items: [
  //       { image: galleryImage, title: 'image title A' },
  //       { image: galleryImage, title: 'image title B' },
  //       { image: galleryImage, title: 'image title C' },
  //     ],
  //   },
  // ];

  return galleryItems?.length ? (
    <FilterTabs data={galleryItems} />
  ) : (
    <div className='grid grid-cols-2 lg:grid-cols-3 gap-4 mt-7 px-10'>
      {[1, 2, 3, 4, 5, 6, 7, 8, 8, 9].map((item, id) => (
        <div
          key={id}
          className='bg-gray-200 animate-pulse h-64 w-full rounded-lg'
        ></div>
      ))}
    </div>
  );
}

const FilterTabs = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedItem, setSelectedItem] = useState(data[0]);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleTabClick = (index) => {
    setActiveTab(index);
    setSelectedItem(data[index]);
  };
  const handleClick = (image) => {
    setSelectedImage(image);
  };
  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleDownload = () => {
    // Create a link element
    const link = document.createElement("a");
    // Set the href attribute to the image URL
    link.href = selectedImage.image;
    // Set the download attribute to the desired file name
    link.download = selectedImage.title;
    // Append the link to the document
    document.body.appendChild(link);
    // Trigger a click on the link to start the download
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div className='px-10 mt-10'>
      <div className='flex overflow-x-auto pb-3'>
        {data?.map((category, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            style={{
              flex: 1,
              padding: "10px",
            }}
            className={`border-r min-w-[200px] ${
              activeTab === index ? "bg-native-blue text-white" : "bg-white"
            }`}
          >
            {category.title}
          </button>
        ))}
      </div>

      <div style={{ marginTop: "20px" }}>
        <h4 className='mb-4'>{selectedItem?.title}</h4>
        {selectedItem?.items?.length ? (
          <div className='grid grid-cols-2 lg:grid-cols-3 gap-4 mt-7'>
            {selectedItem?.items?.map((item, id) => (
              <div key={id}>
                {item.image !== "" && (
                  <>
                    <p>{item.title}</p>
                    <div
                      className='h-72 w-full mt-7 relative active:scale-95 transition-all'
                      onClick={() => handleClick(item)}
                    >
                      <img
                        src={item?.image}
                        alt={item?.project_title}
                        style={{ maxWidth: "100%" }}
                        className='h-full w-full rounded-xl object-cover'
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <h3 className='mt-10 text-gray-400'>No media submitted!</h3>
        )}
        {selectedImage && (
          <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75'>
            <div className='rounded-xl flex-col px-10 flex mx-4 pt-4 bg-white shadow-lg'>
              <div className='flex items-center mb-4 justify-between'>
                <p className='text-lg m-0 font-bold'>{selectedImage?.title}</p>
                <button
                  className='text-black self-end text-xl rounded-xl py-2'
                  onClick={handleClose}
                >
                  <AiOutlineClose />
                </button>
              </div>
              <img
                src={selectedImage?.image}
                alt='Selected Image'
                className='max-h-[500px] rounded-xl'
              />
              <button
                onClick={handleDownload}
                className='text-black active:scale-95 transition-all self-end text-xl rounded-xl py-2'
              >
                <BsDownload className='h-16 bg-gray-200 px-3 rounded-full mb-4 w-16' />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
